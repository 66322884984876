import { Table } from "antd";
const { Column } = Table;
import { moneda } from "../../common/settings";

const TableComponentSelected = ({
    items, handleDelete,handleChangeQuantity,handleSave
}) => {
    return (
        <div>
            <div className="my-2 text-end">
                <div className="d-flex">
                    <div className="py-1 pe-3" style={{ flexGrow: '1', textAlign: 'right', fontSize: '1.2em' }}>
                        <b>Total({moneda()}):</b>
                        <span className="px-2">
                            { items.reduce((acum, current) => {
                                return (parseFloat(current.total_cost) + parseFloat(acum)).toFixed(2);
                            }, 0.00) }
                        </span>
                    </div>
                    <button className="btn btn-sm btn-dark"
                        onClick={handleSave}
                    >
                        Guardar
                    </button>
                </div>
            </div>
            <Table
                dataSource={items}
                rowKey="id"
                size="small"
                // loading={inventario.loading}
                bordered
                scroll={{ y: 200, }}
            >
                <Column title="·" dataIndex="id" width={30}
                    render={(value) => (<>
                        <button className="btn btn-sm btn-danger py-0"
                            onClick={() => handleDelete(value)}
                        >
                            <i className="fas fa-xmark"></i>
                        </button>
                    </>)}
                />
                <Column title="ID" dataIndex="suply" width={30}
                    render={(value) => (<>{value.id}</>)}
                />
                <Column title="Nombre" dataIndex="suply" width={100}
                    render={(value, row) => `${value.code ? `(${value.code}) ` : ''}${value.name}`}
                />
                <Column title="Stock / Costo Unitario" dataIndex="stock" width={50}
                    render={(value, row) => (<>
                        {row.stock ? row.stock : 0} /  {row.actual_cost ? parseFloat(row.actual_cost).toFixed(2) : 0}
                    </>)}
                />

                <Column title="Cantidad" dataIndex="quantity" width={50}
                    align="right"
                    render={(value, row) => (<>
                        <input type="number"
                            className="form-control form-control-sm"
                            value={value} min={0} max={row.stock ?? 0} step="any"
                            onChange={(e) => {handleChangeQuantity(row.id, e.target.value)}}
                        />
                    </>)}
                />

                <Column title="Costo" dataIndex="total_cost" width={50}
                    align="right"
                    render={(value, row) => (<>
                        {parseFloat(value).toFixed(2)}
                    </>)}
                />

            </Table>
        </div>
    );
}

export default TableComponentSelected;
