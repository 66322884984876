import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { EditableContext } from "../common/EditableContextProvider";
import TableComponent from "./baja/TableComponent";
import TableComponentSelected from "./baja/TableComponentSelected";
import useInventario from "./useInventario";
import { Modal } from 'antd';

const FormComponentBaja = ({errors}) => {
    const inventario = useInventario();
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleTable = (row) => {
        // alert(JSON.stringify(row));

        let selectedItem = {
            id: row.id,
            suply: row,
            quantity: 0,
            total_cost: 0,
            stock: row.values_sum_quantity,
            actual_cost: row.values_avg_value
        };

        let found = selectedItems.find(si => si.suply.id === row.id);

        if(!found) {
            setSelectedItems(prev => [...prev, selectedItem]);
        }
    }

    const handleDelete = (suply_id) => {
        setSelectedItems(prev => prev.filter(si => si.id !== suply_id));
    }

    const handleChangeQuantity = (suply_id, quantity) => {
        let items = selectedItems.map((si) => {
            if(si.id === suply_id) {
                let t_cost = (parseFloat(si.suply.values_avg_value) * quantity).toFixed(2);
                return {...si, quantity: quantity, total_cost: t_cost};
            } else { return si; }
        });
        setSelectedItems(items);
    }

    const handleSave = async () => {
        // console.table(selectedItems);
        try {
            let total = selectedItems.reduce((acum, current) => acum + current.total_cost, 0);

            if(!total) {
                Modal.warning({
                    title: "Información",
                    content: "Nada que procesar."
                });
                return;
            }

            let req = await inventario.bajaInsumos({items: selectedItems});
            setSelectedItems([]);

            Modal.info({
                title: "Información",
                content: "Datos procesados correctamente."
            });
        } catch {
            console.log("Error al procesar.");
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <TableComponent handle={handleTable} />
                </div>
                <div className="col-md-6">
                    <TableComponentSelected
                        items={selectedItems}
                        handleDelete={handleDelete}
                        handleChangeQuantity={handleChangeQuantity}
                        handleSave={handleSave}
                    />
                </div>
            </div>

        </div>
    );
}

export default FormComponentBaja;
