import { Table, App } from "antd";
import { useRef, useContext } from "react";
const { Column } = Table;
import { sprintf } from "sprintf-js";
import dayjs from "dayjs";
import { EditableContext } from "../common/EditableContextProvider";

const TableComponent = () => {
    const { modal } = App.useApp();
    const { sales, moneda } = useContext(EditableContext);
    const searchInput = useRef(null);

    const ActionButtons = ({ item }) => {
        return (
            <>
                {
                    (!item.undone) ? (
                        <button className="btn btn-sm btn-danger" title="Deshacer compra..."
                            onClick={() => { sales.saleUndo(item.id) }}
                        >
                            <span className="fas fa-circle-xmark"></span>
                        </button>
                    ) : ""
                }
                <button className="ms-1 btn btn-sm btn-dark" title="Detalles..."
                    onClick={() => { showItems(item); }}

                >
                    <span className="fas fa-list-ul"></span>
                </button>
            </>
        );
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const TableItems = ({ item }) => {
        const { items } = item;
        return (
            <div>
                <div className="row mb-3">
                    <div className="col-2 fw-bold">FECHA:</div>
                    <div className="col-4">{ item.date }</div>
                    <div className="col-2 fw-bold">CLIENTE</div>
                    <div className="col-4">{ (item.person) ? item.person.name : "S/N" }</div>
                    <div className="col-3 fw-bold">MODO DE PAGO:</div>
                    <div className="col-3">{ item.pay_method }</div>
                    <div className="col-2 fw-bold">FACTURA:</div>
                    <div className="col-4">{ item.invoice }</div>
                </div>
                <Table
                    size="small"
                    dataSource={items}
                    rowKey='id'
                    scroll={{ y: 300, x: 'max-content' }}
                    bordered
                    footer={() => (
                        <div className="text-end">
                            TOTAL({moneda}): <b>{ item.total }</b>
                        </div>
                    )}
                >
                    <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                        width={80}
                        render={(value) => sprintf('%04d', value)}
                    />
                    <Column title="PRODUCTO" dataIndex="product" key="product.name"
                        width={200}
                        render={(value) => (<>{value?.name}</>)}
                    />
                    <Column title="CANTIDAD" dataIndex="quantity" key="cantidad"
                        width={80} align="right"
                    />
                    <Column title="PRECIO UNITARIO" dataIndex="unit_price" key="precio_unitario"
                        width={80} align="right"
                    />
                    <Column title="TOTAL" dataIndex="total" key="total"
                        width={80} align="right"
                    />
                </Table>

            </div>
        );
    }

    const showItems = (item) => {
        modal.info({
            width: 700,
            title: 'VENTA',
            content: (<TableItems item={item} />)
        });
    }

    return (
        <Table
            size="small"
            dataSource={sales.items}
            rowKey='id'
            loading={sales.loading}
            onChange={sales.handleTableChange}
            pagination={{ ...sales.tableParams.pagination, position: ['bottomCenter'], showSizeChanger: true }}
            scroll={{ y: 600, x: 'max-content' }}
            bordered
        >
            <Column title="*" dataIndex="id" key="buttons" width={100} className="text-center"
                render={(id, row) => (<ActionButtons item={row} />)}

            />
            <Column title="ID" dataIndex="id" key="id" align="center" className="fw-bold"
                sorter={true}
                width={80}
                render={(value) => sprintf('%04d', value)}
            />
            <Column title="FECHA" dataIndex="date" key="date"
                sorter={true}
                width={100}
                render={(value) => (<>{dayjs(value).format('DD-MM-YYYY HH:mm:ss')}</>)}
            />
            <Column title="CLIENTE" dataIndex="person" key="person.name"
                sorter={true}
                width={250}
                render={(value) => (<>{value?.name?? "S/N"}</>)}
                filterDropdown={getColumnSearchProps('person').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('person').onFilterDropdownOpenChange}
            />
            <Column title="NIT" dataIndex="person" key="person.nodoc"
                sorter={true}
                width={100}
                render={(value) => (<>{value?.nodoc?? 0}</>)}
                filterDropdown={getColumnSearchProps('nodoc').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('nodoc').onFilterDropdownOpenChange}
            />
            <Column title="DESCRIPCION" dataIndex="description" key="description"
                sorter={true}
                width={250}
                filterDropdown={getColumnSearchProps('description').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('description').onFilterDropdownOpenChange}
            />

            <Column title={`TOTAL(${moneda})`} dataIndex="total" key="total"
                sorter={true} align="right"
                width={100}
                filterDropdown={getColumnSearchProps('total').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('total').onFilterDropdownOpenChange}
            />

            <Column title="MODO DE PAGO" dataIndex="pay_method" key="pay_method"
                sorter={true}
                width={120}
                filterDropdown={getColumnSearchProps('pay_method').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('pay_method').onFilterDropdownOpenChange}
            />

            <Column title="FACTURA" dataIndex="invoice" key="invoice"
                sorter={true}
                width={100}
                filterDropdown={getColumnSearchProps('invoice').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('invoice').onFilterDropdownOpenChange}
            />

            <Column title="CUENTA1" dataIndex="ac_entry_deb" key="ac_entry_deb.name"
                sorter={true}
                width={180}
                render={(value, row) => (<>{value.name}</>)}
                filterDropdown={getColumnSearchProps('ac_entry_deb').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('ac_entry_deb').onFilterDropdownOpenChange}
            />

            <Column title="USUARIO" dataIndex="user" key="user.name"
                sorter={true}
                width={150} align="center"
                render={(value) => (<>{value?.name}</>)}
                filterDropdown={getColumnSearchProps('user').filterDropdown}
                onFilterDropdownOpenChange={getColumnSearchProps('user').onFilterDropdownOpenChange}
            />

        </Table>
    );
}

export default TableComponent;
