import { Table } from "antd";
import useInventario from "../useInventario";
import { useEffect, useState } from "react";
const { Column } = Table;

const TableComponent = ({handle}) => {
    const [search, setSearch] = useState('');
    const inventario = useInventario();

    useEffect(() => {
        inventario.fillItems();
    }, []);

    const handleSearch = () => {
        let params = inventario.tableParams;
        params = {...params, filters: {
            ...params.filters,
            name: [search],
        }}
        inventario.fillItems(params);
    }

    return (
        <div>
            <div className="">
                <div className="input-group input-group-sm mb-3">
                    <span className="input-group-text">Nombre:</span>
                    <input type="text" className="form-control" onChange={(e) => setSearch(e.target.value) } />
                    <button className="btn btn-sm btn-light border"
                        onClick={handleSearch}
                    >
                        <i className="fas fa-search me-1"></i>
                        Buscar
                    </button>
                </div>
            </div>
            <Table
                dataSource={inventario.items}
                rowKey="id"
                size="small"
                loading={inventario.loading}
                bordered
                scroll={{ y: 200, x: 'max-content' }}
            >
                <Column title="ID" dataIndex="id" width={80} />
                <Column title="Nombre" dataIndex="name" width={150}
                    render={(value, row) => `${row.code ? `(${row.code}) ` : ''}${value}`}
                />
                <Column title="Stock" dataIndex="values_sum_quantity" align="center" width={100}
                    render={(value) => value ? value : 0 }
                />
                <Column title="Precio Unitario" dataIndex="values_avg_value"
                    align="right" width={100}
                    render={(value) => value ? parseFloat(value).toFixed(2) : 0 }
                />
                <Column title="·" dataIndex='id'
                    render={(value, row) => (
                        <>
                            <button
                                className="btn btn-sm btn-dark"
                                onClick={() => handle(row)}>
                                <span className="fas fa-arrow-right"></span>
                            </button>
                        </>
                    )}
                />
            </Table>
        </div>
    );
}

export default TableComponent;
