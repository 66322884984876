import React from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { App, Modal } from 'antd';
import { EditableContextProvider } from "../common/EditableContextProvider";
import useInventario from "./useInventario";
import FormComponent from "./FormComponent";
import FormComponentBaja from "./FormComponentBaja";

export default function Main() {
    const { editItem, fillItems, items, selectItem, newItem, saveItem, destroyItem, loading, tableParams } = useInventario();
    const [formVisible, setFormVisible] = useState(false);
    const [bajaVisible, setBajaVisible] = useState(false);
    const { modal, notification } = App.useApp();

    useEffect(() => {
        fillItems();
    }, []);
    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                await saveItem(item);
                notification.success({ description: "¡Guardado correcto!" });
                resolve();
            } catch (e) {
                if (e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        modal.confirm({
            content: `¿Eliminar ítem (ID: ${id}) ?`,
            onOk: async () => {
                try {
                    await destroyItem(id);
                    notification.info({ message: "¡Item eliminado!" });
                } catch ({ response }) {
                    notification.error({ message: "Ocurrió un error al eliminar el ítem." });
                    console.log(response.data);
                }
            }
        });
    }
    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }

    return (
        <div>
            <div className='my-1 py-1 row'>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border"
                        onClick={() => { addNewItem(); }}
                    >
                        <i className="fa-solid fa-plus me-1"></i>
                        Agregar
                    </button>
                    <a href="/empresas/transferencias" className="btn btn-sm btn-light border ms-1" target="_blank">
                        <i className="fa-solid fa-arrow-right-arrow-left me-1"></i>
                        TRANSFERENCIAS
                    </a>
                    <button className="btn btn-sm btn-light border ms-1"
                        onClick={() => { setBajaVisible(true) }}
                    >
                        <i className="fa-solid fa-minus me-1"></i>
                        Dar de baja
                    </button>
                </div>
                <div className="col-md">
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border" type="button"
                        onClick={() => { livewire.emitTo('suplies.table', 'get-csv') }}>
                        <span className="fas fa-table me-1"></span>
                        Descargar CSV
                    </button>
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border" type="button"
                        onClick={() => { livewire.emit('show_upload_show') }}
                    >
                        <span className="fas fa-upload me-1"></span>
                        Cargar CSV
                    </button>
                </div>

            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
            />

            <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction
                }}
                >
                    <FormComponent />
                </EditableContextProvider>
            </Modal>

            <Modal title="Dar de baja"
                width={1100}
                open={bajaVisible}
                onCancel={() => {setBajaVisible(false)}}
                destroyOnClose={true}
                footer={null}
            >
                <EditableContextProvider data={{
                    items: items
                }}
                >
                    <FormComponentBaja />
                </EditableContextProvider>
            </Modal>
        </div>
    );
}

let main = document.getElementById('suplies-table');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <App>
                    <Main />
                </App>
            </ErrorBox>
        </React.StrictMode>
    );
}
